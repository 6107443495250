import React from 'react'
import styled from 'styled-components'

import Icon100Nights from '../../images/icons/icon-100-nights.svg'
import IconPayments from '../../images/icons/icon-large-secure-payments.svg'
import IconDelivery from '../../images/icons/icon-large-delivery.svg'
import IconReturns from '../../images/icons/icon-large-returns.svg'
import IconGuarantee from '../../images/icons/icon-large-guarantee.svg'
import IconAward from '../../images/icons/icon-award.svg'

import deliveryIcon from '../../images/icons/delivery.svg'
import securePaymentsIcon from '../../images/icons/secure-payments.svg'

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.grey1};
  width: 100%;
  padding: 0;

  // The inner locked width section
  div {
    max-width: ${props => props.theme.maxWidth};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    padding: 10px;

    h6 {
      color: ${props => props.theme.grey3};
      text-transform: uppercase;
      font-size: 14px;
    }

    // The individual icon adn text containters
    div {
      width: 160px;
      height: 160px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-self: center;
      .icon0 {
        width: 70px;
        height: 50px;
      }
      .icon1 {
        width: 75px;
        height: 45px;
      }
      .icon2 {
        width: 65px;
        height: 50px;
      }
      .icon3 {
        width: 60px;
        height: 45px;
      }
      .icon4 {
        // width: 55px;
        // height: 50px;
        width: 61px;
        height: 61px;
      }
      .icon5 {
        width: 72px;
        height: 61px;
      }
      @media (max-width: 1024px) {
        width: 140px;
        height: 140px;
      }
      @media (max-width: 520px) {
        width: 120px;
        height: 100px;
        padding: 10px;
        h6 {
          font-size: 10px;
        }
      }
    }

    // Small icons on small screen sizes
    .item0 {
      @media (max-width: 520px) {
        img {
          width: 39px;
          height: 28px;
        }
      }
    }
    .item4 {
      @media (max-width: 520px) {
        img {
          width: 33px;
          height: 30px;
        }
      }
    }
    .item5 {
      @media (max-width: 520px) {
        img {
          width: 40px;
          height: 33px;
        }
      }
    }

    // Hide icons on smaller screen sizes
    .item1 {
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .item2 {
      @media (max-width: 768px) {
        display: none;
      }
    }
    .item3 {
      @media (max-width: 768px) {
        display: none;
      }
    }

    // Reduce grid on smaller screens
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 15px 0;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 10px 0;
    }
  }
`

const IconsRow = ({reviews, productId}) => {
  return (
    <IconsWrapper>
      <div>
        <div className="item0">
          <img
            src={Icon100Nights}
            alt="120 Night In-Home Comfort Trial"
            className="icon0"
          />
          <h6>120 Night In-Home Comfort Trial</h6>
        </div>
        {/* <div className="item1">
          <img src={IconDelivery} alt="Fast Delivery" className="icon1" />
          <h6>Fast Delivery</h6>
        </div> */}
        {/* <div className="item2">
          <img src={IconPayments} alt="Secure Checkout" className="icon2" />
          <h6>Secure Checkout</h6>
        </div> */}
        <div className="item3">
          <img src={IconReturns} alt="Easy Returns" className="icon3" />
          <h6>Easy Product Returns</h6>
        </div>
        {/*<div className="item4">*/}
        {/*  <img src={IconGuarantee} alt="3 Year Guarantee" className="icon4" />*/}
        {/*  <h6>3-Year Product Guarantee</h6>*/}
        {/*</div>*/}
        {/*<div className="item5">*/}
        {/*  <img*/}
        {/*    src={IconAward}*/}
        {/*    alt="20 Year Service Warranty"*/}
        {/*    className="icon5"*/}
        {/*  />*/}
        {/*  <h6>20-Year Service Warranty</h6>*/}
        {/*</div>*/}
        <div className="item4">
          <img src={deliveryIcon} alt="Nationwide Delivery" className="icon4" />
          <h6>Nationwide Shipping (Free in Main City Hubs)</h6>
        </div>
        <div className="item5">
          <img
            src={securePaymentsIcon}
            alt="Secure Payments"
            className="icon5"
          />
          <h6>Easy & Secure payments</h6>
        </div>
      </div>
    </IconsWrapper>
  )
}

export default IconsRow
